import React from "react";
import { Form, Row } from "react-bootstrap";

export default class VersioneForm extends React.Component {
    render() {
        const disableCondition = this.props.disableCondition;
        const onVersioneChange = this.props.onChange;
        const versione = this.props.versione;
        const versioniList = this.props.versioniList;
        return (
            <Row>
                <Form.Group className="mb-3" controlId="versione">
                    <Form.Label>Versione</Form.Label>
                    <Form.Select
                        disabled={disableCondition}
                        onChange={onVersioneChange}
                        value={versione || ""}
                    >
                        <option hidden value="">Seleziona Versione ...</option>
                        {
                            versioniList && versioniList.map(({ codiceMotornetUnivoco, nome }) =>
                                <option key={codiceMotornetUnivoco} value={codiceMotornetUnivoco}>{nome}</option>
                            )
                        }
                    </Form.Select>
                </Form.Group>
            </Row>
        )
    }
}