import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { withAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';

import MmvForm from './forms/mmv-form.tsx';
import CodeForm from './forms/code-form.tsx';
import { Container, Tab, Tabs } from 'react-bootstrap';
import React, { useState } from 'react';
import Navbar from './navbar.tsx';
import { EvaluationHistory } from './forms/history.tsx';
import PlateInput from './forms/plate-form-v2';
import VinInput from './forms/vin-input';
import { user_has_group } from './util';
import { Queue } from './forms/queue.tsx';

export function isSeller(user) {
  let isSeller = true;
  try {
    isSeller = user.signInUserSession.accessToken.payload["cognito:groups"].includes('Venditori');
  } catch { }
  return isSeller;
}

function App({ signOut, user }) {
  // Check for failed plate interrogation
  const [failedPlate, setFailedPlate] = useState(false);
  const [currentTab, setCurrentTab] = useState("plate");
  const [plate, setPlate] = useState(null);

  function onFailedPlate(plate) {
    setFailedPlate(true);
    setPlate(plate);
  }

  // We don't show MMV and Code to Sellers unless plate interrogation failed
  const seller = isSeller(user);

  return (
    <>
      <Navbar signout={signOut} user={user} />
      <Container className="main-window mt-3">
        <Tabs
          activeKey={currentTab}
          onClick={(evt) => { setCurrentTab(evt.target.dataset.rrUiEventKey) }}
          id="noanim-tabs-selection"
          className="mb-3"
        >
          {user_has_group(user, "VinUser") && <Tab eventKey="vin" title="Telaio">
            <div className='reduced-window'>
              <VinInput
                user={user}
              />
            </div>
          </Tab>}
          <Tab eventKey="plate" title="Targa">
            <div className="reduced-window">
              <PlateInput
                user={user}
                onFailedPlate={onFailedPlate}
                onClickedSearchForMmv={(plate) => { setCurrentTab("mmv"); setFailedPlate(true); console.log(`Setting plate ${plate}`); setPlate(plate); }}
                onPlateError={(plate, e) => { alert("Non è stato possibile ricevere informazioni sulla targa interrogata, si è pregati di procedere con la ricerca per marca/modello"); setFailedPlate(true); setCurrentTab("mmv"); console.log(`Setting plate ${plate}, error: ${e}`); setPlate(plate) }}
              />
            </div>
          </Tab>
          {(!seller || failedPlate) && <Tab eventKey="mmv" title="Marca/Modello">
            <div className="reduced-window">
              <MmvForm user={user} plate={plate} />
            </div>
          </Tab>}
          {!seller && <Tab eventKey="code" title="Codice">
            <div className="reduced-window">
              <CodeForm user={user} />
            </div>
          </Tab>}
          <Tab eventKey="history" title="Riepilogo">
            <EvaluationHistory user={user} />
          </Tab>


          {(user_has_group(user, "RichiesteAvanzate") || user_has_group(user, "ApprovazioneRichieste") || user_has_group(user, "BackOfficeRA")) &&
            < Tab eventKey="queue" title="Coda Valutazioni">
              <Queue user={user} />
            </Tab>
          }

          {/* <Tab eventKey="approve" title="Approva Valutazioni">
            <EvaluationHistory user={user} />
          </Tab> */}
        </Tabs>
        <div style={{ height: '10em' }} />
      </Container >
    </>
  );
}

export default withAuthenticator(App);
