import React, { useState } from "react";
import OutputBox from "../output-box";
import { Button, Card, Col, Container, FloatingLabel, Form, InputGroup, Row, Modal, OverlayTrigger } from "react-bootstrap";
import { user_has_group } from "../../util";
import { EvaluationStatus, updateEvaluationRequest } from "./evaluation.tsx";
import { decodeVatTypeN, VatType, VatTypeN } from "./vatType.tsx";

interface ModalOutputBoxProps {
    user: any,
    evaluationDetails: JSON | null,
    queueDetails: JSON | null,
    activeEvaluationDetail: any,
    onClose?: () => void,
    onUpdate?: ((selectPage, activeIndex, status) => void),
    selectPage?: number,
    activeIndex?: number,
}

export function ModalOutputBox(props: ModalOutputBoxProps) {
    let lcn = "";
    try {
        lcn = props.evaluationDetails.success.laCompriamoNoi.evaluation.value;
    } catch { }

    const [confirmedLcn, setConfirmedLcn] = useState(lcn);
    const [confirmedDamages, setConfirmedDamages] = useState("");

    function handleLcnChange(lcn: string | undefined): void {
        let v = "";
        try {
            v = String(parseInt(lcn))
        } catch { }
        setConfirmedLcn(v);
    }

    function handleDamagesChange(damages: string | undefined): void {
        let v = "";
        try {
            v = String(parseInt(damages))
        } catch { }
        setConfirmedDamages(v);
    }

    function submitApprovation(price?: number, status: EvaluationStatus, notes?): void {
        const evaluationId = props.evaluationDetails.success.rebounce.evaluation.id;
        const damages = parseInt(confirmedDamages) || 0;
        // Update Queue state
        if (props.onUpdate) {
            props.onUpdate(props.selectPage, props.activeIndex, status);
        }
        updateEvaluationRequest(props.user.user.getSignInUserSession().getIdToken().getJwtToken(), {
            evaluationId,
            price,
            damages,
            approvationStatus: status,
            notes: notes
        });
    }

    let inQueue = false;
    try {
        inQueue = props.queueDetails.stato == "In attesa"
    } catch { }

    return <Container className="reduced-window">
        <>
            <Button onClick={props.onClose} style={{ width: '100%' }}>
                Torna Indietro
            </Button>
            <OutputBox
                json={props.evaluationDetails}
                immatr={props.evaluationDetails?.success?.rebounce?.registrationDate}
                km={props.evaluationDetails?.success?.rebounce?.km}
                user={props.user.user}
                queueDetails={props.queueDetails}
                evaluationId={props.evaluationDetails?.success?.rebounce?.evaluation?.id}
            />
            {user_has_group(props.user.user, "ApprovazioneRichieste") && inQueue && <PriceApprovationBox
                lcn={confirmedLcn}
                onLcnChange={handleLcnChange}
                damages={confirmedDamages}
                onLcnChange={handleLcnChange}
                onDamagesChange={handleDamagesChange}
                onApprove={(price) => submitApprovation(price, EvaluationStatus.APPROVED)}
                onReject={(notes) => submitApprovation(null, EvaluationStatus.REJECTED, notes)}
                queueDetails={props.queueDetails}
                vatType={props.evaluationDetails?.success?.rebounce?.vatType}
            />}
            {!inQueue && props.queueDetails && props.queueDetails.stato === 'Approvata' && <Card className="mb-3 evaluation-approved">
                <Card.Header>Valutazione Approvata</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <h4>La valutazione è stata approvata</h4>
                        <p>
                            La richiesta è stata inviata il <span>{props.evaluationDetails
                                && props.evaluationDetails.success
                                && props.evaluationDetails.success.rebounce
                                && props.evaluationDetails.success.rebounce.evaluation
                                && props.evaluationDetails.success.rebounce.evaluation.time
                                && (new Date(props.evaluationDetails.success.rebounce.evaluation.time)).toLocaleString()
                            }</span> e approvata da <span>{props.queueDetails && props.queueDetails.approvata_da} </span>
                            in data <span>{props.queueDetails
                                && props.queueDetails.data_approvazione
                                && (new Date(props.queueDetails.data_approvazione)).toLocaleString()
                            }</span>
                        </p>
                    </Card.Text>
                </Card.Body>
            </Card>}
            {!inQueue && props.queueDetails && props.queueDetails.stato === 'Rifiutata' && <Card className="mb-3 evaluation-deny">
                <Card.Header>Valutazione Rifiutata</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <h4>
                            La valutazione è stata rifiutata.
                        </h4>
                        <p>
                            La richiesta è stata inviata il <span>{props.evaluationDetails
                                && props.evaluationDetails.success
                                && props.evaluationDetails.success.rebounce
                                && props.evaluationDetails.success.rebounce.evaluation
                                && props.evaluationDetails.success.rebounce.evaluation.time
                                && (new Date(props.evaluationDetails.success.rebounce.evaluation.time)).toLocaleString()
                            }</span> e rifiutata da <span>{props.queueDetails && props.queueDetails.approvata_da} </span>
                            in data <span>{props.queueDetails
                                && props.queueDetails.data_approvazione
                                && (new Date(props.queueDetails.data_approvazione)).toLocaleString()
                            } </span>
                            per le motivazioni che seguono
                        </p>
                        <dl>
                            <dt>Motivazione</dt>
                            <dd>{(props.queueDetails && props.queueDetails.note) || `Non sono presenti annotazioni`}</dd>
                        </dl>
                    </Card.Text>
                </Card.Body>
            </Card>}
            {inQueue && <Card className="mb-3 evaluation-queue">
                <Card.Header>Valutazione in coda</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <h4>La valutazione è in coda.</h4>
                        <p>Inviata il <span>
                            {props.evaluationDetails
                                && props.evaluationDetails.success
                                && props.evaluationDetails.success.rebounce
                                && props.evaluationDetails.success.rebounce.evaluation
                                && props.evaluationDetails.success.rebounce.evaluation.time
                                && (new Date(props.evaluationDetails.success.rebounce.evaluation.time)).toLocaleString()
                            }
                        </span>
                        </p>
                    </Card.Text>
                </Card.Body>
            </Card>}
            <Button onClick={props.onClose} style={{ width: '100%' }}>
                Torna Indietro
            </Button>
        </>
    </Container>;
}

interface PriceApprovationBoxProps {
    lcn?: string,
    damages?: string,
    onLcnChange: (lcn: string | undefined) => void,
    onDamagesChange: (damages: string | undefined) => void,
    onApprove: (price?: number) => void,
    onReject: (notes: string | undefined) => void,
    queueDetails: any,
    vatType?: VatType
}

function PriceApprovationBox(props: PriceApprovationBoxProps) {
    let priceValue = 0;
    try {
        const x = parseInt(props.lcn || "0");
        priceValue = isNaN(x) ? 0 : x;
    } catch { }

    try {
        const x = parseInt(props.damages || "0");
        priceValue -= isNaN(x) ? 0 : x;
    } catch { }

    try {
        if (props.vatType === VatTypeN.PARZIALE) {
            priceValue *= 1.0 - 0.118
        }
    } catch { }


    const [textAreaValue, setTextAreaValue] = useState('');
    const [denyModalShow, setDenyModalShow] = useState(false);

    const handleTextAreaChange = (event) => {
        setTextAreaValue(event.target.value);
    };

    const handleReject = () => {
        props.onReject(textAreaValue);
    };

    function handleDenyModalShow(show: boolean) {
        setDenyModalShow(show);
    }

    const denyModal = (
        <Modal size="lg" aria-labelledby="contained-modal-notes-vcenter" centered show={denyModalShow} onHide={() => handleDenyModalShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-notes-modal-vcenter">Rifiuta Richiesta di Valutazione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Note</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Inserisci qui le note"
                            value={textAreaValue}
                            onChange={handleTextAreaChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <InputGroup>
                    <Button className="col-8" onClick={handleReject} variant="danger">Rifiuta</Button>
                    <Button className="col-4" onClick={() => handleDenyModalShow(false)} variant="secondary">Chiudi</Button>
                </InputGroup>
            </Modal.Footer>
        </Modal>
    );

    return (props.queueDetails && props.queueDetails.stato === 'In attesa' && <div className="modify-evaluation-box mb-3">
        <h2 style={{ textAlign: "center" }}>Modifica Valutazione</h2>
        <Form>
            <Row className="mb-3">
                <Form.Group as={Col} className="col-12">
                    <FloatingLabel label="Valutazione di Ritiro" className="mb-3">
                        <Form.Control
                            type="number"
                            value={props.lcn}
                            onChange={(evt) => props.onLcnChange(evt.target.value)}
                            step={100}
                            min={0}
                        />
                    </FloatingLabel>
                    <FloatingLabel label="Stima Danni" className="mb-3">
                        <Form.Control
                            type="number"
                            value={props.damages}
                            onChange={(evt) => props.onDamagesChange(evt.target.value)}
                            step={100}
                            min={0}
                        />
                    </FloatingLabel>
                    <FloatingLabel label="Tipo IVA" className="mb-3">
                        <Form.Control
                            disabled={true}
                            value={(props.vatType && decodeVatTypeN(props.vatType)) || `Assente`}
                        />
                    </FloatingLabel>
                    <Col className="mb-3">
                        <Card style={{ backgroundImage: `url("lacompriamonoi_symbol.png")` }} className="col-6">
                            <Card.Header className="col-12"><Card.Title>{`Nuovo Valore di Ritiro`}</Card.Title></Card.Header>
                            <Card.Body>
                                <Card.Text>{`€ ${Math.floor((parseInt(priceValue) / 10)) * 10}`}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <InputGroup className="input-group-lg mb-3">
                        <Button className="col-6" variant="success" onClick={() => props.onApprove(priceValue)}>Approva</Button>
                        <Button className="col-6" variant="danger" onClick={() => handleDenyModalShow(true)}>Rifiuta</Button>
                    </InputGroup>
                    {denyModal}
                </Form.Group>
            </Row>
        </Form>
    </div >
    );
}


