import React, { useRef, useState, useEffect } from 'react';
import { Col, Image, Row } from "react-bootstrap";

export function SinglePhotosUploader({ index, image, updateImageAtIndex }) {
    const fileInputRef = useRef(null);

    const [backgroundImage, setBackgroundImage] = useState("");

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    let photoTitle = "";
    switch (index) {
        case 0:
            photoTitle = "Fronte";
            break;
        case 1:
            photoTitle = "Retro";
            break;
        case 2:
            photoTitle = "Fiancata DX";
            break;
        case 3:
            photoTitle = "Fiancata SX";
            break;
        case 4:
            photoTitle = "Ruota Anteriore DX";
            break;
        case 5:
            photoTitle = "Tetto Frontale";
            break;
        case 6:
            photoTitle = "Tetto Laterale";
            break;
        case 7:
            photoTitle = "Sedili Posteriori";
            break;
        case 8:
            photoTitle = "Sedili Anteriori";
            break;
        case 9:
            photoTitle = "Bagagliaio";
            break;
        case 10:
            photoTitle = "Quadro";
            break;
        case 11:
            photoTitle = "Motore";
            break;
        case 12:
            photoTitle = "Battistrada";
            break;
        case 13:
            photoTitle = "Cruscotto";
            break;
        case 14:
            photoTitle = "Libretto Fronte";
            break;
        case 15:
            photoTitle = "Libretto Retro";
            break;
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64 = e.target.result;
                updateImageAtIndex(index, base64);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (image) {
            setBackgroundImage(`url(${image.fullBase64})`);
        } else {
            setBackgroundImage(`url(placeholder${index}.jpg)`);
        }
    })


    let opacity = image ? 1 : 0.5;
    let border = image ? '2px solid #00cb00' : '2px solid';

    return (
        <Col className="col-lg-3 col-6 mb-3">
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="image/jpeg, image/png"
            />
            <Image
                className="single-image-uploader"
                onClick={handleImageClick}
                style={{ cursor: 'pointer', width: '100%', height: '132px', backgroundImage: backgroundImage, backgroundPosition: 'center', backgroundSize: 'cover', opacity: opacity, border: border }}
            />
            <h6 className="mt-1 photosuploader-caption">{photoTitle}</h6>
        </Col>
    );
}
export default function PhotosUploader({ setAllPhotosFilledCallback, setPhotosPayload }) {
    // Initial array state with 14 null values
    const [imagePayload, updateImagePayload] = useState(new Array(16).fill(null));

    useEffect(() => {
        // Call the callback function only if all images are uploaded
        const allUploaded = imagePayload.every((payload) => payload !== null);
        if (allUploaded) {
            setAllPhotosFilledCallback(true);
            setPhotosPayload(imagePayload);
        }
    }, [imagePayload]);

    const updateImageAtIndex = (index, base64) => {
        let base64String = base64.split(',')[1];
        let mimeType = base64.split(';')[0].split(':')[1];
        let extension = mimeType.split('/')[1];
        updateImagePayload((prevPayload) => {
            const newPayload = [...prevPayload];
            newPayload[index] = {
                name: index + '.' + extension,
                bytes: base64String,
                mime: mimeType,
                fullBase64: base64
            };
            return newPayload;
        });
    };
    return (
        <Row className="mt-3">
            <Col className="col-12 mt-3 mb-2">
                <h6>Carica tutte le immagini del veicolo per proseguire</h6>
            </Col>
            {imagePayload.map((image, index) => (
                <SinglePhotosUploader
                    key={index}
                    index={index}
                    image={image}
                    updateImageAtIndex={updateImageAtIndex}
                />
            ))}
        </Row>
    );
}