import React from "react";
import { Form, Row } from "react-bootstrap";

export default class MarcaForm extends React.Component {
    render() {
        const disableCondition = this.props.disableCondition;
        const onMarcaChange = this.props.onChange;
        const marca = this.props.marca;
        const marcheList = this.props.marcheList;
        return (
            <Row>
                <Form.Group
                    className="mb-3"
                    controlId="marcaInputId"
                >
                    <Form.Label>Marca</Form.Label>
                    <Form.Select
                        disabled={disableCondition}
                        onChange={onMarcaChange}
                        value={marca || ""}
                    >
                        <option value="" hidden>Seleziona Marca ...</option>
                        {
                            marcheList && marcheList.map(({ acronimo, nome }) => <option key={acronimo} value={acronimo}>{nome}</option>)
                        }

                    </Form.Select>
                </Form.Group>
            </Row>
        )
    }
}