import React from 'react';
import { Form, FloatingLabel } from "react-bootstrap";

export default function Notes(props) {
    const onChange = props.handleNotesChange;
    return (
        <>
            <Form.Group>
                <FloatingLabel label="Note aggiuntive" className="mb-3">
                    <Form.Control as="textarea" onChange={onChange} name="noteSeller" style={{ 'height': '100px' }} />
                </FloatingLabel>
            </Form.Group>
        </>
    );
}
