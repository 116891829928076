import React from "react";
import { Button, Container, Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { user_has_group } from "./util";

export default function AutobroNavbar({ signout, user }): JSX.Element {

    return (
        <>
            <Navbar bg="light" fixed="bottom">
                <Container fluid>
                    <Nav className="me-auto" style={{ 'alignItems': 'center' }}>
                        <Button variant="link" onClick={signout}>Logout</Button>
                        <p style={{ 'margin': 0 }}>{user.attributes.email}</p>
                    </Nav>


                    {user_has_group(user, "Limited") || <Navbar.Text><img className="carzooon-logo" alt="Carzooon Logo" src="Carzooon_logo_nopayoff.jpg" /></Navbar.Text>}
                </Container>
            </Navbar>
        </>
    )
}