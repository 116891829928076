import React from "react"
import { Button, InputGroup } from "react-bootstrap";
import { mdiReplay, mdiTextBoxSearchOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { user_has_group } from "../../util";

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function FormSubmitWithReset({ user, disableCondition, onClickSubmit, onClickAdvancedSubmit, onClickReset }) {
    return <InputGroup>
        <InputGroup className="input-group-lg">
            <Button disabled={disableCondition} className="flex-grow-1" title="Ottieni Valutazione" onClick={() => onClickSubmit && onClickSubmit()}>Invia</Button>
            {user && user_has_group(user, "Journal") && <Button disabled={disableCondition} className="col-4" variant="secondary" title="Ottieni Valutazione Avanzata" onClick={() => onClickAdvancedSubmit && onClickAdvancedSubmit()}><Icon path={mdiTextBoxSearchOutline} size={1} /></Button>}
            <Button variant="danger"><Icon path={mdiReplay} size={1} title="Reset" onClick={() => onClickReset && onClickReset()} /></Button>
        </InputGroup>
    </InputGroup>
}

// export default class FormSubmitWithReset extends React.Component {
//     constructor(props) {
//         super(props)
//     }

//     render() {
//         const disableCondition = this.props.disableCondition;
//         const onClickSubmit = this.props.onClickSubmit;
//         const onClickReset = this.props.onClickReset;
//         const user = this.props.user;
//         return (
//             <div className="d-grid gap-2">
//                 <Button
//                     type="submit" size="lg"
//                     disabled={disableCondition}
//                     onClick={onClickSubmit}
//                 >
//                     Invia
//                 </Button>
//                 <Button
//                     type="reset"
//                     variant="outline-danger"
//                     onClick={onClickReset}
//                 >
//                     Reset
//                 </Button>
//             </div>
//         )
//     }
// }