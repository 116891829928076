import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { useState } from "react";
import moment from "moment";
import App, { isSeller } from "../App";
import { user_has_group } from "../util.js";

const API_ENDPOINT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/eval-history";

const convert_moment = (moment) => {
    return moment.format("YYYY-MM-DD HH:mm:ss")
}

export function EvaluationHistory(user) {
    // States to keep track of the user input
    const [intervalType, setIntervalType] = useState("between");
    const [operator, setOperator] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [entriesList, setEntriesList] = useState(new Array());
    const [page, setPage] = useState(0);
    const [selectPage, setSelectPage] = useState(0);
    const [limit, setLimit] = useState(false);
    const [availableOperators, setAvailableOperators] = useState(new Array());
    const didAskForUsersRef = useRef(false);
    const [adminUserName, setAdminUserName] = useState("");
    const [searchPlate, setSearchPlate] = useState("");
    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");
    const [adminAllUsers, setAdminAllUsers] = useState(false);
    const [updatingOperatorsList, setUpdatingOperatorsList] = useState(false);
    const [vinSearch, setVinSearch] = useState("");
    const [vinSearchCheck, setVinSearchCheck] = useState(false);

    let seller = isSeller(user.user);

    useEffect(() => {
        // To avoid multiple interrogations
        if (didAskForUsersRef.current) {
            return;
        }
        didAskForUsersRef.current = true;

        try {
            let token = user.user.getSignInUserSession().getIdToken().getJwtToken();

            fetch(API_ENDPOINT, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "get_users",
                    "id": 1263
                })
            })
                .then((e) => e.json())
                .then((e) => {
                    setAvailableOperators(e.users)
                });
        } catch (e) { }
    }, [user.user]);

    // Convert from local timezone to UTC
    const from = moment(moment(startDate).valueOf()).utc();
    const to = moment(moment(endDate).valueOf()).utc();

    // Get user groups
    let userGroups;
    try {
        userGroups = user.user.signInUserSession.accessToken.payload["cognito:groups"];
    } catch {
        userGroups = [];
    }

    // Callback for when the interval type changes
    function onIntervalChange(evt) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setIntervalType(evt.target.value);
        setLimit(false);
    }

    // Callback for the operator input field changes
    function onOperatorChange(operator) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setOperator(operator);
        setLimit(false);
    }

    // Callback for the operator input field changes
    function onAdminUserChange(name) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setOperator("");
        setAdminUserName(name);
        setLimit(false);
    }

    function onAdminUserClick() {
        setAvailableOperators(new Array());

        try {
            let token = user.user.getSignInUserSession().getIdToken().getJwtToken();

            let body = JSON.stringify({
                "jsonrpc": "2.0",
                "method": "get_users_admin",
                "params": [
                    {
                        "user": adminUserName
                    }
                ],
                "id": 1263
            });

            if (adminUserName.length < 1) {
                body = JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "get_users",
                    "id": 1263
                });
            }

            fetch(API_ENDPOINT, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body
            })
                .then((e) => e.json())
                .then((e) => {
                    setAvailableOperators(e.users)
                });
        } catch (e) { }
    }

    // Callback for changes in start date field
    function onStartDateChange(evt) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setStartDate(evt.target.value);
        setLimit(false);
    }

    // Callback for changes in end date field
    function onEndDateChange(evt) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setEndDate(evt.target.value);
        setLimit(false);
    }

    // Callback for changes in plate search input
    function onSearchPlateChange(evt) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setSearchPlate(evt.target.value);
        setLimit(false);
    }

    // Callback for brand searching
    function onBrandChange(evt) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setLimit(false);
        setBrand(evt.target.value);
    }

    function onModelChange(evt) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setLimit(false);
        setModel(evt.target.value);
    }

    function onVinChange(evt) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setLimit(false);
        setVinSearch(evt.target.value);
    }

    function onVinCheckChange(evt) {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setLimit(false);
        setVinSearchCheck(evt.target.checked);
    }

    function onAdminSetAllUsers() {
        setEntriesList(new Array());
        setPage(0);
        setSelectPage(0);
        setAdminAllUsers(!adminAllUsers);
        setLimit(false);

        // Silently update operators list
        try {
            setUpdatingOperatorsList(true);
            let token = user.user.getSignInUserSession().getIdToken().getJwtToken();

            if (!adminAllUsers) {
                // We need to list all users
                let body = JSON.stringify({
                    "jsonrpc": "2.0",
                    "method": "get_users_admin",
                    "params": [
                        {
                            "all": true
                        }
                    ],
                    "id": 2773
                });

                fetch(API_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    body
                })
                    .then((e) => e.json())
                    .then((e) => {
                        setAvailableOperators(e.users)
                    })
                    .catch((e) => alert("Impossibile portare a termine la richiesta"))
                    .finally(() => setUpdatingOperatorsList(false))
                    ;
            } else {
                // We only list user operators
                fetch(API_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        "jsonrpc": "2.0",
                        "method": "get_users",
                        "id": 1263
                    })
                })
                    .then((e) => e.json())
                    .then((e) => {
                        setAvailableOperators(e.users)
                    })
                    .catch((e) => alert("Impossibile portare a termine la richiesta"))
                    .finally(() => setUpdatingOperatorsList(false))
                    ;
            }
        } catch (e) { }
    }

    const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

    function onPageIncrement() {
        if (selectPage >= page) {
            handleSubmit();
        } else {
            setSelectPage(clamp(selectPage + 1, 1, page));
        }
    }

    function onPageDecrement() {
        setSelectPage(clamp(selectPage - 1, 1, page));
    }

    function onPageInputChange(evt) {
        setSelectPage(clamp(evt.target.value, 1, page));
    }

    // Callback for submit button press
    function handleSubmit() {
        // If we reached the limit, we won't continue
        if (limit) {
            return;
        }

        setIsSearching(true);

        // Retrieve user token
        try {
            let token = user.user.getSignInUserSession().getIdToken().getJwtToken();

            let body = JSON.stringify({
                page,
                "operator": operator || null,
                "date": {
                    "from": ((intervalType === "between" || intervalType === "from") && (from.isValid() && convert_moment(from))) || null,
                    "to": ((intervalType === "between" || intervalType === "to") && (to.isValid() && convert_moment(to))) || null
                },
                "plate": searchPlate || null,
                "brand": brand || null,
                "model": model || null,
                "user": (!adminAllUsers && adminUserName) || null,
                "onlyVins": vinSearchCheck,
                "vin": vinSearch || null,
                "all": adminAllUsers || null,
            });

            // Make the request
            fetch("https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/eval-history", {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body
            })
                .then((e) => e.json())
                .then((e) => {
                    if (e.length > 0) {
                        entriesList.push(e);
                        setEntriesList([...entriesList]);
                        setPage(page + 1);
                        setSelectPage(page + 1);

                        // If we have less than 10 elements, we surely hit the limit
                        if (e.length < 10) {
                            setLimit(true);
                        }
                    } else {
                        setLimit(true);
                    }
                })
                .finally(() => setIsSearching(false));
        } catch {
            alert("Sessione scaduta, riaggiornare la pagina. In caso l'errore persista, contattare il supporto.");
            setIsSearching(false);
        }
    }

    // Conditional printing
    let entries: null | React.ReactElement;
    if (selectPage > 0) {
        const id = selectPage - 1;
        if (entriesList && entriesList[id]) {
            entries =
                entriesList[id].map((e, i: number) => {
                    return <tr key={`row-${i}`} className="report-row">
                        {operator.length > 0 || <td key={`user-${i}`} className="operator"><span>{e.user}</span></td>}
                        <td key={`timestamp-${i}`}>{e.timestamp}</td>
                        <td key={`plate-${i}`}>{(e.plate && e.plate.toUpperCase()) || "-"}</td>
                        {user_has_group(user.user, "VinUser") && <td key={`vin-${i}`}>{e.vin || "-"}</td>}
                        <td key={`regdate-${i}`}>{e.registrationDate || "-"}</td>
                        <td key={`motornet-${i}`}>{e.motornetCode || "-"}</td>
                        <td key={`km-${i}`}>{e.km || "-"}</td>
                        <td key={`brand-${i}`}>{e.brand || "-"}</td>
                        <td key={`model-${i}`}>{e.briefModel || "-"}</td>
                        <td key={`version-${i}`}>{e.version || "-"}</td>
                        {!user_has_group(user.user, "RichiesteAvanzate") && <td key={`autobro-${i}`}>{e.autobro || "-"}</td>}
                        {!user_has_group(user.user, "Limited") && !user_has_group(user.user, "RichiesteAvanzate") && <td key={`lcn-${i}`}>{e.lcn || "-"}</td>}
                        {!user_has_group(user.user, "RichiesteAvanzate") && <td key={`reliability-${i}`}>{(e.reliability && `${(e.reliability * 100).toFixed(0)}%`) || "-"}</td>}
                        {!user_has_group(user.user, "RichiesteAvanzate") && <td key={`streetprice-${i}`}>{e.streetprice || "-"}</td>}
                    </tr>
                });
        } else {
            entries = <Spinner></Spinner>;
        }
    } else {
        entries = null;
    };

    return <Form>
        <Row className="mb-3">
            <Col className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="formOpName">
                    <Form.Label>Operatore</Form.Label>
                    <Form.Select aria-label="Selezione Operatore" onChange={(e) => onOperatorChange(e.target.value)} disabled={updatingOperatorsList}>
                        <option key="all-ops" value="">Tutti</option>
                        {availableOperators.map((e, i) => {
                            return <option key={`op-${i}`} value={e}>{e}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            </Col>
            {user_has_group(user.user, 'AdminReportListing') &&
                <Col className="col-12 col-md-6">
                    <Form.Group className="mb-3" controlId="formOpAdminName">
                        <Form.Label>Admin User</Form.Label>
                        <InputGroup>
                            <InputGroup.Checkbox aria-label="Checkbox for listing all users" checked={adminAllUsers} onChange={onAdminSetAllUsers}></InputGroup.Checkbox>
                            <Form.Control type="text" onChange={(e) => onAdminUserChange(e.target.value)} disabled={adminAllUsers}></Form.Control>
                            <Button onClick={onAdminUserClick} disabled={adminAllUsers}>{adminUserName ? `Cerca` : `Reset`}</Button>
                        </InputGroup>
                    </Form.Group>
                </Col>}
            <Col className="col-12 col-md-6">
                <Form.Label>Data Valutazione</Form.Label>
                <Row>
                    <Form.Group className="mb-3" controlId="formDateIntervalType">
                        {(intervalType === "between" || intervalType === "from") && <Row className="mt-1">
                            {intervalType === "between" && <Col className="col-12 col-md-3"><Form.Label>Inizio</Form.Label></Col>}
                            <Col><Form.Control type="datetime-local" onChange={onStartDateChange} value={startDate}></Form.Control></Col>
                        </Row>}

                        {(intervalType === "between" || intervalType === "to") &&
                            <Row>
                                {intervalType === "between" && <Col className="col-12 col-md-3"><Form.Label>Fine</Form.Label></Col>}
                                <Col><Form.Control type="datetime-local" onChange={onEndDateChange} value={endDate}></Form.Control></Col>
                            </Row>
                        }
                    </Form.Group>
                </Row>
            </Col>
            <Col className="col-12 col-md-6 mb-3">
                <Form.Label>Targa</Form.Label>
                <Row>
                    <Col><Form.Control type="input" onChange={onSearchPlateChange} value={searchPlate}></Form.Control></Col>
                </Row>
            </Col>
            <Col className="col-12 col-md-6 mb-3">
                <Form.Label>Marca</Form.Label>
                <Row>
                    <Col><Form.Control type="input" onChange={onBrandChange} value={brand}></Form.Control></Col>
                </Row>
            </Col>
            <Col className="col-12 col-md-6 mb-3">
                <Form.Label>Modello</Form.Label>
                <Row>
                    <Col><Form.Control type="input" onChange={onModelChange} value={model}></Form.Control></Col>
                </Row>
            </Col>

            {user_has_group(user.user, 'VinUser') &&
                <Col className="col-12 col-md-6 mb-3">
                    <Form.Group className="mb-3" controlId="formOpAdminName">
                        <Form.Label>VIN</Form.Label>
                        <InputGroup>
                            <InputGroup.Checkbox aria-label="Checkbox for listing only VIN interrogations" onClick={onVinCheckChange}></InputGroup.Checkbox>
                            <Form.Control type="text" disabled={vinSearchCheck} value={vinSearchCheck ? 'Cerca tutte le interrogazioni VIN' : vinSearch} onChange={onVinChange} />
                        </InputGroup>
                    </Form.Group>
                </Col>}
        </Row>

        {entriesList.length <= 0 && <Row>
            <Col><Button style={{ width: '100%' }} disabled={isSearching || limit} onClick={handleSubmit}>{((limit && `Fine Risultati`) || (isSearching && <Spinner></Spinner>)) || `Invia`}</Button></Col>
        </Row>}

        <Container className="overflow-auto">
            {entriesList.length > 0 && <Table className="history-table">
                <thead>
                    <tr key="table-header">
                        {operator.length > 0 || <th key="user">Utente</th>}
                        <th key="timestamp">Data</th>
                        <th key="plate">Targa</th>
                        {!seller && user_has_group(user.user, "VinUser") && <th key="vin">VIN</th>}
                        <th key="registration-date">Immatricolazione</th>
                        <th key="motornet">Motornet</th>
                        <th key="km">KM</th>
                        <th key="brand">Marca</th>
                        <th key="model">Modello</th>
                        <th key="version">Allestimento</th>
                        {!user_has_group(user.user, "RichiesteAvanzate") && <th key="autobro">{!user_has_group(user.user, "Limited") ? `Autobro` : `Valore Vendita`}</th>}
                        {!user_has_group(user.user, "Limited") && !user_has_group(user.user, "RichiesteAvanzate") && <th key="lcn">LCN</th>}
                        {!user_has_group(user.user, "RichiesteAvanzate") && <th key="reliability">Affidabilità</th>}
                        {!user_has_group(user.user, "RichiesteAvanzate") && !user_has_group(user.user, "Limited") && <th key="streetprice">Streetprice</th>}
                    </tr>
                </thead>
                <tbody>
                    {entries}
                </tbody>
            </Table>}
        </Container>
        {entriesList.length > 0 && <Form.Group className="listing-nav">
            <Row>
                <Col style={{ textAlign: "right" }}><Button onClick={onPageDecrement} disabled={selectPage <= 1}>{`<`}</Button></Col>
                <Col style={{ width: 10 }}><Form.Control type="number" style={{ textAlign: "center" }} min={1} value={selectPage || 0} onChange={onPageInputChange} /></Col>
                <Col style={{ textAlign: "left" }}><Button onClick={onPageIncrement} disabled={isSearching || limit && selectPage >= page}>{'>'}</Button></Col>
            </Row>
        </Form.Group>}
    </Form >
}