import React, { useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, InputGroup, Modal, Row, Spinner, Table } from "react-bootstrap";
import { PLATE_API_ENDPOINT, PLATE_FORMAT_LENGTH, PLATE_VALIDITY_REGEX } from "../config";

import Icon from '@mdi/react';
import { mdiAlertCircleOutline, mdiCarCog, mdiCarSearch, mdiClose, mdiReplay, mdiTextBoxSearchOutline } from "@mdi/js";
import { user_has_group } from "../util";
import evaluateVehicle from "./components/evaluation.tsx";
import OutputBox from "./output-box.tsx";

import PhotosUploader from './components/photosUploader.tsx';
import { VatTypeBox, VatTypeN } from "./components/vatType.tsx";
import Notes from "./components/notes.tsx";
export function checkPlateValidity({ plate }) {
    return plate && plate.length >= PLATE_FORMAT_LENGTH ? PLATE_VALIDITY_REGEX.test(plate) : true
}

export function VehicleDescriptor({ brand, model, version, motornetCode, eurotaxCode, registrationDate, foreignRegistrationDate, foreignState, vin }) {
    return <Container className="vehicle-descriptor">
        <Row className="vehicle-header"><Col><Icon path={mdiCarCog} size={1} /></Col></Row>
        <Row>
            <Col>
                {brand && <Row>
                    <Col className="col-12 col-md-6 text-center text-start"><strong>Marca</strong></Col>
                    <Col className="col-12 col-md-6 text-center text-start">{brand}</Col>
                </Row>}
                {model && <Row>
                    <Col className="col-12 col-md-6 text-center text-start"><strong>Modello</strong></Col>
                    <Col className="col-12 col-md-6 text-center text-start">{model}</Col>
                </Row>}
                {eurotaxCode && <Row>
                    <Col className="col-12 col-md-6 text-center text-start"><strong>Eurotax</strong></Col>
                    <Col className="col-12 col-md-6 text-center text-start">{eurotaxCode}</Col>
                </Row>}
                {motornetCode && <Row>
                    <Col className="col-12 col-md-6 text-center text-start"><strong>Motornet</strong></Col>
                    <Col className="col-12 col-md-6 text-center text-start">{motornetCode}</Col>
                </Row>}
                {registrationDate && <Row>
                    <Col className="col-12 col-md-6 text-center text-start"><strong>Immatricolazione</strong>{foreignState && <span className="registration-state d-block d-md-inline">ITALIA</span>}</Col>
                    <Col className="col-12 col-md-6 text-center text-start">{new Date(registrationDate).toLocaleDateString()}</Col>
                </Row>}
                {foreignRegistrationDate && <Row>
                    <Col className="col-12 col-md-6 text-center text-start"><strong>Prima Immatr.</strong>{foreignState && <span className="registration-state d-block d-md-inline">{foreignState || `ESTERO`}</span>}</Col>
                    <Col className="col-12 col-md-6 text-center text-start">{new Date(foreignRegistrationDate).toLocaleDateString()}</Col>
                </Row>}
                {vin && <Row>
                    <Col className="col-12 col-md-6 text-center text-start"><strong>VIN</strong></Col>
                    <Col className="col-12 col-md-6 text-center text-start">{vin}</Col>
                </Row>}
            </Col>
        </Row>
    </Container>
}

export default function PlateInput({ user, onPlateSuccess, onRequestError, onPlateError, onNoVersionsAvailable, onClickedSearchForMmv, onVinError, onVinSuccess }) {

    const [plate, setPlate] = useState('');
    const [searchingPlate, setSearchingPlate] = useState(false);
    const [registrationDate, setRegistrationDate] = useState('');
    const [foreignRegistration, setForeignRegistration] = useState(null);
    const [km, setKm] = useState('');
    const [versions, setVersions] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [vin, setVin] = useState('');
    const [vehicleDesc, setVehicleDesc] = useState(null);
    const [evalResponse, setEvalResponse] = useState(null);
    const [evaluating, setEvaluating] = useState(false);
    const [receivedNoVersions, setReceivedNoVersions] = useState(false);
    const [vinReceivedNoVersions, setVinReceivedNoVersions] = useState(false);
    const [searchingWithVin, setSearchingWithVin] = useState(false);
    const [buyingFromIva, setBuyingFromIva] = useState(false);
    const [areAllPhotosFilled, setAllPhotosFilled] = useState(!user_has_group(user, "RichiesteAvanzate"));
    const [photosPayload, setPhotosPayload] = useState(null);
    const [vatType, setVatType] = useState(VatTypeN.NULL);
    const [notes, setNotes] = useState('');

    function handleEvaluationSubmit(extended) {
        setEvaluating(true);
        setEvalResponse(null);
        evaluateVehicle(
            user.getSignInUserSession().getIdToken().getJwtToken(),
            selectedVersion.codiceMotornetUnivoco,
            (foreignRegistration && foreignRegistration.dataImmatricolazioneEstero) || registrationDate,
            parseInt(km),
            plate,
            extended,
            (m, e, mo) => { },
            (json) => { setEvalResponse(json); setEvaluating(false); },
            vin.length > 0 && searchingWithVin ? vin : null,
            foreignRegistration && {
                nation: foreignRegistration.statoEstero,
                immatr: foreignRegistration.dataImmatricolazioneEstero
            },
            vatType,
            notes
        );
    }

    function handleVatTypeChange(vatType) {
        setVatType(vatType);
    }

    function handleNotesChange(notes) {
        notes = notes.target.value;
        setNotes(notes);
    }

    function handleHardReset() {
        setPlate('');
        setRegistrationDate('');
        setForeignRegistration(null);
        setKm('');
        setVehicleDesc(null);
        setSelectedVersion(null);
        setVersions(null);
        setVin('');
        setEvalResponse(null);
        setEvaluating(false);
        setSearchingWithVin(false);
        setVatType(VatTypeN.TOTALE);
        setPhotosPayload(null);
        setAllPhotosFilled(!user_has_group(user, "RichiesteAvanzate"));
        setBuyingFromIva(false);
        setNotes('');
    }

    function handlePlateChange(evt) {
        const plate = evt.target.value;
        if (plate.length > PLATE_FORMAT_LENGTH) { return; }
        setPlate(plate.toUpperCase());
    }

    function handleKmChange(evt) {
        const km = evt.target.value;
        setKm(km);
    }

    function handlePlateSearch() {
        setSearchingPlate(true);
        setVersions(null);
        setSelectedVersion(null);
        setForeignRegistration(null);
        setRegistrationDate(null);
        setVehicleDesc(null);
        setVinReceivedNoVersions(false);
        setReceivedNoVersions(false);
        setVin('');
        setSearchingWithVin(false);

        fetch(PLATE_API_ENDPOINT, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${user.getSignInUserSession().getIdToken().getJwtToken()}`
            },
            body: JSON.stringify({ targa: plate })
        })
            .then(res => res.json())
            .then(json => {
                try {
                    setVersions(json.success.versioni);
                    setRegistrationDate(json.success.dataImmatricolazione);
                    setForeignRegistration(json.success.esteroInfo);
                    setVehicleDesc(json.success.vehicleDesc);
                    setVin((json && json.success && json.success.vin) || '');
                    if (json.success.versioni.length === 0) {
                        // No versions found
                        onNoVersionsAvailable && onNoVersionsAvailable(vin);
                        setReceivedNoVersions(true);
                        return;
                    } else {
                        setSelectedVersion({
                            codiceMotornetUnivoco: json.success.versioni[0].codiceMotornetUnivoco,
                            name: json.success.versioni[0].versione,
                            eurotaxCode: json.success.versioni[0].codiceEurotax
                        });
                        setReceivedNoVersions(false);
                        onPlateSuccess && onPlateSuccess(json);
                    }
                } catch (e) { return onPlateError && onPlateError(plate, e); }
            })
            .catch(e => onRequestError(e))
            .finally(() => setSearchingPlate(false));
    }

    function handleVersionChange(evt) {
        const selectedVersion = evt.target.options[evt.target.selectedIndex];
        setSelectedVersion({
            codiceMotornetUnivoco: selectedVersion.value,
            name: versions[evt.target.selectedIndex]["versione"],
            eurotaxCode: versions[evt.target.selectedIndex]["codiceEurotax"]
        });
    }

    function handleIvaChange(evt) {
        setBuyingFromIva(evt.target.checked);
    }

    function handleVinSearch(evt) {
        setSearchingPlate(true);
        setVersions(null);
        setSelectedVersion(null);
        setForeignRegistration(null);
        setRegistrationDate(null);
        setVehicleDesc(null);
        setVinReceivedNoVersions(false);
        setSearchingWithVin(true);

        fetch(PLATE_API_ENDPOINT, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${user.getSignInUserSession().getIdToken().getJwtToken()}`
            },
            body: JSON.stringify({ vin })
        })
            .then(res => res.json())
            .then(json => {
                try {
                    setVersions(json.success.versioni);
                    setRegistrationDate(json.success.dataImmatricolazione)
                    setVehicleDesc(json.success.vehicleDesc);
                    if (json.success.versioni.length === 0) {
                        // No version found on VIN interrogation
                        setVinReceivedNoVersions(true);
                        return;
                    } else {
                        setSelectedVersion({
                            codiceMotornetUnivoco: json.success.versioni[0].codiceMotornetUnivoco,
                            name: json.success.versioni[0].versione,
                            eurotaxCode: json.success.versioni[0].codiceEurotax
                        });
                        setVinReceivedNoVersions(false);
                        setReceivedNoVersions(false);
                        onVinSuccess && onVinSuccess(json);
                    }
                } catch (e) { return onVinError && onVinError(e) };
            })
            .catch(e => onRequestError(e))
            .finally(() => setSearchingPlate(false));
        ;
    }



    const invalidPlate = !checkPlateValidity({ plate });
    const canRequestEvaluation =
        selectedVersion
        && (registrationDate || (foreignRegistration && foreignRegistration.dataImmatricolazioneEstero))
        && km
        && (!user_has_group(user, "RichiesteAvanzate") || areAllPhotosFilled)
        && (!user_has_group(user, "RichiesteAvanzate") || (vatType && vatType !== 'NULL'))
        && (!user_has_group(user, "RichiesteAvanzate") || notes.length > 0);

    return <Form>
        <Row className="mb-3">
            <Form.Group as={Col}>
                <InputGroup className="input-group-lg">
                    <FloatingLabel label="Targa">
                        <Form.Control
                            type="text"
                            value={plate}
                            isInvalid={invalidPlate}
                            aria-invalid={invalidPlate}
                            onChange={handlePlateChange}
                            title="Targa in formato italiano AA000AA"
                        />
                    </FloatingLabel>
                    <Button
                        disabled={plate.length !== PLATE_FORMAT_LENGTH || invalidPlate || searchingPlate}
                        onClick={handlePlateSearch}
                        title="Cerca"
                        className="col-4 col-sm-2"
                    >{searchingPlate ? <Spinner className="spinner-grow-sm"></Spinner> : <Icon path={mdiCarSearch} size={1} />}</Button>
                </InputGroup>
            </Form.Group>
        </Row>

        {vehicleDesc && <Row className="mb-3">
            <VehicleDescriptor
                brand={vehicleDesc && vehicleDesc.brand}
                model={vehicleDesc && vehicleDesc.model}
                version={selectedVersion && selectedVersion.name}
                eurotaxCode={selectedVersion && selectedVersion.eurotaxCode}
                motornetCode={selectedVersion && selectedVersion.codiceMotornetUnivoco}
                registrationDate={registrationDate}
                foreignRegistrationDate={foreignRegistration && foreignRegistration.dataImmatricolazioneEstero}
                foreignState={foreignRegistration && foreignRegistration.statoEstero}
                vin={vin}
            />
        </Row>}

        {versions && versions.length > 0 && <Row className="mb-3">
            <Form.Group as={Col} className="col-12 col-md-8 mb-3 mb-md-0">
                <FloatingLabel label="Allestimenti">
                    <Form.Select
                        disabled={versions ? versions.length === 0 : true}
                        onChange={handleVersionChange}
                        value={selectedVersion ? selectedVersion.codiceMotornetUnivoco : ''}
                    >
                        {versions &&
                            versions.map((version, i) => {
                                return <option key={i} value={version.codiceMotornetUnivoco}>{version.versione}</option>
                            })
                        }
                    </Form.Select>
                </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} className="col-12 col-md-4 mb-3">
                <FloatingLabel label="Chilometri">
                    <Form.Control type="number" step={1000} min={0} onChange={handleKmChange} value={km} />
                </FloatingLabel>
            </Form.Group>

            {user_has_group(user, "RichiesteAvanzate") && <VatTypeBox onChange={handleVatTypeChange} />}
            {user_has_group(user, "RichiesteAvanzate") && <PhotosUploader setAllPhotosFilledCallback={(areAllPhotosFilled) => setAllPhotosFilled(areAllPhotosFilled)} setPhotosPayload={(images) => setPhotosPayload({ images })} />}
            {user_has_group(user, "RichiesteAvanzate") && <Notes handleNotesChange={handleNotesChange} />}
        </Row>}


        <Row className="mb-3">
            <InputGroup className="input-group-lg">
                <Button disabled={!canRequestEvaluation} className="flex-grow-1" title="Ottieni Valutazione" onClick={() => handleEvaluationSubmit(false)}>Invia</Button>
                {user_has_group(user, "Journal") && <Button disabled={!canRequestEvaluation} className="col-4" variant="secondary" title="Ottieni Valutazione Avanzata" onClick={() => handleEvaluationSubmit(true)}><Icon path={mdiTextBoxSearchOutline} size={1} /></Button>}
                <Button variant="danger"><Icon path={mdiReplay} size={1} title="Reset" onClick={handleHardReset} /></Button>
            </InputGroup>
        </Row>

        {(evalResponse || evaluating) && < OutputBox
            immatr={(foreignRegistration && foreignRegistration.dataImmatricolazioneEstero) || registrationDate}
            km={km}
            plate={plate}
            json={evalResponse}
            user={user}
            iva={buyingFromIva}
            onIvaChange={handleIvaChange}
            photos={photosPayload}
            evaluationId={evalResponse && evalResponse.success && evalResponse.success.rebounce && evalResponse.success.rebounce.evaluation && evalResponse.success.rebounce.evaluation.id}
        />}

        <Modal show={receivedNoVersions} onHide={() => setReceivedNoVersions(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Icon path={mdiAlertCircleOutline} size={1} /> Nessuna Versione Trovata
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{`Non è stato possibile recuperare gli allestimenti associati alla targa ${plate}.`}</p>
                {user_has_group(user, "VinUser") && vinReceivedNoVersions && <p>Non è stato possibile recuperare le informazioni sul veicolo utilizzando il telaio, procedere con ricerca per marca/modello.</p>}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => { setReceivedNoVersions(false); onClickedSearchForMmv(plate) }}>Cerca per Marca/Modello</Button>
                {user_has_group(user, "VinUser") && !vinReceivedNoVersions && vin !== '' && < Button variant="secondary" onClick={handleVinSearch}>Cerca per VIN</Button>}
            </Modal.Footer>
        </Modal>
    </Form >;
}