import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

export namespace VatTypeN {
    export const TOTALE = "IVA_TOTALE";
    export const PARZIALE = "IVA_PARZIALE";
    export const REGIME_MARGINE = "REGIME_MARGINE";
    export const NULL = "NULL";
}

export function decodeVatTypeN(vatType: VatType): string {
    switch (vatType) {
        case VatTypeN.TOTALE:
            return "Totale";
        case VatTypeN.PARZIALE:
            return "Parziale";
        case VatTypeN.REGIME_MARGINE:
            return "Regime del Margine"
        default:
            return "";
    }
}

export type VatType = typeof VatTypeN[keyof typeof VatTypeN];

interface VatTypeBoxProps {
    onChange?: (status: VatType) => void
}

export function VatTypeBox(props: VatTypeBoxProps) {
    const onChange = props.onChange || (() => { });
    return (
        <Form.Group className="mb-3">
            <FloatingLabel label="Tipo IVA">
                <Form.Select
                    defaultValue={""}
                    onChange={(e) => {
                        const value = e.target.value === "" ? null : e.target.value as VatType;
                        onChange(value);
                    }}
                >
                    <option value={VatTypeN.NULL}>Seleziona Tipo IVA</option>
                    <option value={VatTypeN.TOTALE}>Totale</option>
                    <option value={VatTypeN.REGIME_MARGINE}>Regime del Margine</option>
                    <option value={VatTypeN.PARZIALE}>Parziale</option>
                </Form.Select>
            </FloatingLabel>
        </Form.Group>
    );
}