import { AmplifyUser } from '@aws-amplify/ui'
import { Button, Card, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import OutputBox from './output-box.tsx';
import DateKmForm from './components/datekm';
import FormSubmitWithReset from './components/formsubmit';
import evaluateVehicle from './components/evaluation.tsx';

const API_TAXMOT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/taxmot";

enum CodeType {
    Eurotax = "Eurotax",
    MotornetUnivoco = "Motornet Univoco",
}

type CodeSearchProps = {
    user: AmplifyUser;
}

type Vehicle = {
    marca: string,
    allestimento: string,
    mot_uni: string,
}

type CodeSearchState = {
    kind: CodeType,
    input?: string,
    inputFeedback?: React.ReactNode,
    vehicle?: Vehicle,
    output?: React.ReactNode
    anno?: number,
    mese?: number,
    km?: number,
    notifications?: React.ReactNode,
    codes?: { motUni: string, etax: string, motOld: string }
    evaluating: boolean,
    evalResponse?: JSON,
}

export default class CodeForm extends React.Component<CodeSearchProps, CodeSearchState> {
    state: CodeSearchState = {
        kind: CodeType.Eurotax,
        evaluating: false,
    }

    constructor(props: CodeSearchProps) {
        super(props)

        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleCodeTypeChange = this.handleCodeTypeChange.bind(this);
        this.handleCodeSubmit = this.handleCodeSubmit.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormReset = this.handleFormReset.bind(this);
    }

    handleCodeChange(evt) {
        const input = evt.target.value;
        this.setState({
            input
        });
    }

    handleCodeTypeChange(evt) {
        const id = evt.target.selectedIndex;
        const kind = evt.target.options[id].value;
        this.setState({
            kind
        })
    }

    handleCodeSubmit(evt) {
        evt.preventDefault();
        this.setState({
            inputFeedback: <Spinner variant="primary" />,
            vehicle: undefined,
            output: undefined,
            anno: undefined,
            mese: undefined,
            km: undefined,
        })
        fetch(API_TAXMOT, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${this.props.user.getSignInUserSession()?.getIdToken().getJwtToken()}`
            },
            body: JSON.stringify({ code: this.state.input, kind: this.state.kind })
        })
            .then((r) => {
                switch (r.status) {
                    case 412: {
                        alert("Errore nei parametri, ricontrolla i valori inseriti");
                        throw new Error("client error")
                    }
                    case 500: {
                        alert("Il server ha riscontrato un errore con i parametri specificati. Riprova tra qualche minuto o con altri valori.")
                        throw new Error("server error");
                    }
                    default: {
                        return r.json()
                    }
                }
            })
            .then((json) => {
                const success = json["success"];
                const vehicle = {
                    mot_uni: success["codiceMotornetUnivoco"],
                    allestimento: success["allestimento"],
                    marca: success["marca"]
                }
                const legacy = success["legacy"];
                const legacyStr = `${legacy["eurotax"] ? "Eurotax: " + legacy["eurotax"] : ""}${legacy["motornetOld"] ? " - Motornet (Legacy): " + legacy["motornetOld"] : ""}`

                this.setState({
                    vehicle,
                    inputFeedback: <Card bg="light" body>{vehicle.marca} {vehicle.allestimento}<br />Motornet Univoco: {vehicle.mot_uni}<br />{legacyStr}</Card>
                });
            })
            .catch((r) => {
                this.setState({
                    inputFeedback: undefined
                });
            });
    }

    handleFormSubmit() {
        if (this.state.anno === undefined || this.state.mese === undefined || this.state.km === undefined || this.state.vehicle === undefined) return;
        this.setState({
            evaluating: true,
            evalResponse: undefined,
        });
        const mot = this.state.vehicle.mot_uni;
        const dataImmatr = `${this.state.anno}-${('0' + this.state.mese).slice(-2)}-01`;
        const km = this.state.km;
        const token = this.props.user.getSignInUserSession()?.getIdToken().getJwtToken();
        evaluateVehicle(token ? token : "", mot, dataImmatr, km, null, false,
            (motUni: string, etax: number, motOld: string) => this.setState({
                codes: { motUni, etax: String(etax), motOld }
            }),
            (json: JSON) => this.setState({ evalResponse: json }),
            null
        );
    }

    handleFormReset(evt) {
        evt.preventDefault();
        this.setState({
            anno: undefined,
            input: undefined,
            inputFeedback: undefined,
            km: undefined,
            mese: undefined,
            vehicle: undefined,
            output: undefined,
            evaluating: false,
            codes: undefined,
            evalResponse: undefined,
        })
    }

    render() {
        return (
            <>
                <Form className="container-fluid">
                    <Row className="mb-3">
                        <Form.Group controlId="codeInput" as={Col}>
                            <Form.Label>Codice {this.state.kind}</Form.Label>
                            <Row>
                                <Col className="col-12 col-sm p-1">
                                    <Form.Control
                                        onChange={this.handleCodeChange}
                                        value={this.state.input ? this.state.input : ""}
                                    />
                                </Col>
                                <Col className="col-12 col-sm p-1">
                                    <Form.Select
                                        onChange={this.handleCodeTypeChange}
                                        value={this.state.kind}
                                    >
                                        {Object.values(CodeType).map((v) => {
                                            return <option key={v} value={v}>{v}</option>
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col className="col-12 col-sm-3 p-1">
                                    <Button
                                        disabled={this.state.input ? this.state.input == "" : true}
                                        className="col-12 col-sm w-100"
                                        onClick={this.handleCodeSubmit}
                                        type="submit"
                                    >
                                        Cerca
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row >
                    <Row>
                        <Col className="text-center mb-3">
                            {this.state.inputFeedback}
                        </Col>
                    </Row>
                    {this.state.inputFeedback &&
                        <>
                            <DateKmForm
                                onYearChange={(evt) => this.setState({ anno: parseInt(evt.target.value) })}
                                onMonthChange={(evt) => this.setState({ mese: parseInt(evt.target.value) })}
                                onKmChange={(evt) => this.setState({ km: parseInt(evt.target.value) })}
                                year={this.state.anno}
                                month={this.state.mese}
                                km={this.state.km}
                            />
                            <FormSubmitWithReset
                                disableCondition={this.state.vehicle === undefined || this.state.km === undefined || this.state.anno === undefined || this.state.mese === undefined}
                                onClickSubmit={this.handleFormSubmit}
                                onClickReset={this.handleFormReset}
                            />
                        </>
                    }
                </Form >
                {this.state.evaluating &&
                    <OutputBox
                        codes={this.state.codes}
                        km={this.state.km || 0}
                        immatr={`${String(this.state.anno).padStart(4, '0')}-${String(this.state.mese).padStart(2, '0')}-01`}
                        json={this.state.evalResponse}
                        user={this.props.user}
                    />
                }
                {this.state.notifications !== undefined && this.state.notifications}
            </>
        )
    }
}