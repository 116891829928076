import React from "react";
import { Form, Row } from "react-bootstrap";

export default class ModelloForm extends React.Component {
    render() {
        const disableCondition = this.props.disableCondition;
        const onModelloChange = this.props.onChange;
        const modello = this.props.modello;
        const modelliList = this.props.modelliList;
        return (<Row>
            <Form.Group className="mb-3" controlId="modello">
                <Form.Label>Modello</Form.Label>
                <Form.Select
                    disabled={disableCondition}
                    onChange={onModelloChange}
                    value={modello || ""}
                >
                    <option hidden value="">Seleziona Modello ...</option>
                    {
                        modelliList && modelliList.map(({ codice, descrizione }) =>
                            <option key={codice} value={codice}>{descrizione}</option>
                        )
                    }
                </Form.Select>
            </Form.Group>
        </Row>)
    }
}