import React from "react";
import Form from "react-bootstrap/Form";
import { Col, FloatingLabel, Row, Spinner } from "react-bootstrap";
import { AmplifyUser } from '@aws-amplify/ui'
// @ts-ignore
import OutputBox from "./output-box.tsx";
import FormSubmitWithReset from "./components/formsubmit";
import DateKmForm from "./components/datekm";
import MarcaForm from "./components/marcasel";
import ModelloForm from "./components/modelloForm";
import VersioneForm from "./versioneForm";
import evaluateVehicle from "./components/evaluation.tsx";
import PhotosUploader from "./components/photosUploader.tsx";
import { VatType, VatTypeBox, VatTypeN } from "./components/vatType.tsx";
import Notes from "./components/notes.tsx";
import { user_has_group } from "../util.js";
import ProgressBar from 'react-bootstrap/ProgressBar';
const MMV_API_ENDPOINT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/mmv";

type MmvProps = {
    user: AmplifyUser;
    plate?: string;
};

type MmvState = {
    initMarche: boolean,
    marche?: Array<{ acronimo: string, nome: string }>;
    modelli?: Array<{ codice: string, descrizione: string }>;
    versioni?: Array<{ codiceMotornetUnivoco: string, nome: string }>;
    anno: string,
    mese: string,
    selMarca: string | null,
    selModello: string | null,
    selVersione: string | null,
    km: string,
    output?: React.ReactNode,
    marcaName?: string,
    versioneName?: string,
    evalResponse?: JSON,
    evaluating: boolean,
    codes?: { motUni: string, etax: string, motOld: string }
    areAllPhotosFilled: boolean,
    photosPayload: { images: Array<string> },
    vatType?: VatType,
    notes?: string,
    progressBarValue?: number
};

/**
 * Contiene informazioni sul componente che abilita il processo di valutazione
 * su Marche - Modelli - Versioni (acr. mmv)
 */
class MmvForm extends React.Component<MmvProps, MmvState> {
    state: MmvState = {
        initMarche: false,
        anno: "",
        selMarca: null,
        selModello: null,
        selVersione: null,
        km: "",
        mese: "1",
        evaluating: false,
        areAllPhotosFilled: false,
        photosPayload: {
            images: []
        },
        vatType: VatTypeN.NULL,
        progressBarValue: 0
    };


    constructor(props: MmvProps) {
        super(props)

        this.loadMarche = this.loadMarche.bind(this);
        this.loadModelli = this.loadModelli.bind(this);

        this.handleMarcaChange = this.handleMarcaChange.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleAnnoChange = this.handleAnnoChange.bind(this);
        this.handleModelloChange = this.handleModelloChange.bind(this);
        this.handleVersioneChange = this.handleVersioneChange.bind(this);
        this.handleKmChange = this.handleKmChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleVatTypeChange = this.handleVatTypeChange.bind(this);

    }

    setAllPhotosFilled() {
        this.setState({ areAllPhotosFilled: true });
    }

    setPhotosPayload(images) {
        this.setState({ photosPayload: images }, () => {
        });

    }

    setProgressBarValue = (value: number) => {
        this.setState({ progressBarValue: value });
    }


    handleVatTypeChange = (vatType) => {
        this.setState({ vatType: vatType });
    }

    setNotes(notes) {
        this.setState({ notes: notes });
    }

    loadMarche() {
        this.setState({
            initMarche: true,
        });
        fetch(MMV_API_ENDPOINT, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${this.props.user.getSignInUserSession()?.getIdToken().getJwtToken()}`
            },
            body: JSON.stringify({ state: 0 })
        }).then((r) => r.json())
            .then((json) => {
                this.setState({
                    marche: json["marche"]
                });
            }).catch((r) => alert("Error while contacting server " + r));
    }

    loadModelli(acronimo: string | null, annoImmatr: string) {
        if (annoImmatr.length != 4 || acronimo == null) return;
        fetch(MMV_API_ENDPOINT, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${this.props.user.getSignInUserSession()?.getIdToken().getJwtToken()}`
            },
            body: JSON.stringify({ state: 1, args: [acronimo, parseInt(annoImmatr)] })
        })
            .then((r) => r.json())
            .then((json) => this.setState({ modelli: json["modelli"] }))
            .catch((r) => alert("Error while contacting server" + r));
    }

    loadVersioni(codice: string | null, annoImmatr: string) {
        if (annoImmatr.length != 4 || codice == null) return;
        fetch(MMV_API_ENDPOINT, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${this.props.user.getSignInUserSession()?.getIdToken().getJwtToken()}`
            },
            body: JSON.stringify({ state: 2, args: [parseInt(codice), parseInt(annoImmatr)] })
        })
            .then((r) => r.json())
            .then((json) => this.setState({ versioni: json["versioni"] }))
            .catch((r) => alert("Error while contacting server" + r));
    }

    handleAnnoChange(evt) {
        const anno = evt.target.value;
        this.setState({
            anno,
            selModello: null,
            selVersione: null,
        });
        this.loadModelli(this.state.selMarca, anno);
    }

    handleMarcaChange(evt) {
        const id = evt.target.selectedIndex;
        const acronimo = evt.target.options[id].value;
        this.setState({
            selMarca: acronimo,
            selModello: null,
            selVersione: null,
            marcaName: this.state.marche ? this.state.marche[id - 1].nome : "<Non Definita>",
            modelli: undefined,
            versioni: undefined,
        });
        this.loadModelli(acronimo, this.state.anno);
    }

    handleReset() {
        this.setState({
            anno: "",
            mese: "1",
            km: "",
            selMarca: null,
            selModello: null,
            selVersione: null,
            output: undefined,
            evaluating: false,
            codes: undefined,
        })
    }

    handleModelloChange(evt) {
        const codice = evt.target.options[evt.target.selectedIndex].value;
        this.setState({
            selVersione: null,
            selModello: codice,
            versioni: undefined,
        });
        this.loadVersioni(codice, this.state.anno);
    }

    handleVersioneChange(evt) {
        const id = evt.target.selectedIndex;
        const codice = evt.target.options[evt.target.selectedIndex].value;
        this.setState({
            selVersione: codice,
            versioneName: this.state.versioni ? this.state.versioni[id - 1].nome : "<Non Definita>",
        });
    }

    handleKmChange(evt) {
        const km = evt.target.value;
        this.setState({ km });
    }

    handleSubmit(extended) {
        if (this.state.selVersione == null || this.state.anno.length != 4 || this.state.km == "0") return;
        this.setState({
            evaluating: true,
            evalResponse: undefined,
            codes: undefined
        });
        const mot = this.state.selVersione;
        const dataImmatr = `${this.state.anno}-${('0' + this.state.mese).slice(-2)}-01`;
        const km = parseInt(this.state.km);
        var token = this.props.user.getSignInUserSession()?.getIdToken().getJwtToken();
        evaluateVehicle(token ? token : "", mot, dataImmatr, km, this.props.plate || null, extended,
            (motUni: string, etax: number, motOld: string) => {
                this.setState({
                    codes: { motUni, etax: String(etax), motOld }
                })
            },
            (resp) => {
                this.setState({
                    evalResponse: resp
                }, () => {
                });

            }, null, undefined, this.state.vatType, this.state.notes);

    }

    componentDidUpdate(): void {
        if (!this.state.initMarche) { this.loadMarche(); }
    }

    render(): React.ReactNode {
        const notesCompiled = this.state.notes && this.state.notes.length > 0;

        const disableEvaluation =
            this.state.anno.length !== 4
            || this.state.km == ""
            || this.state.selMarca == null
            || this.state.selModello == null
            || this.state.selVersione == null
            || (user_has_group(this.props.user, "RichiesteAvanzate") && !this.state.areAllPhotosFilled)
            || (user_has_group(this.props.user, "RichiesteAvanzate") && this.state.vatType === VatTypeN.NULL)
            || (user_has_group(this.props.user, "RichiesteAvanzate") && !notesCompiled);

        return (
            <>
                <Form className="container-fluid">
                    {this.props.plate && <Form.Group className="mb-3">
                        <FloatingLabel label="Targa">
                            <Form.Control
                                type="text"
                                value={this.props.plate}
                                disabled
                            />
                        </FloatingLabel>
                    </Form.Group>}

                    <DateKmForm
                        onYearChange={this.handleAnnoChange}
                        onMonthChange={(evt) => this.setState({ mese: evt.target.value })}
                        onKmChange={this.handleKmChange}
                        year={this.state.anno}
                        month={this.state.mese}
                        km={this.state.km === undefined ? undefined : this.state.km}
                    />
                    {this.state.anno && this.state.marche &&
                        <MarcaForm
                            disableCondition={!this.state.marche}
                            onChange={this.handleMarcaChange}
                            marca={this.state.selMarca}
                            marcheList={this.state.marche}
                        />}

                    {this.state.selMarca &&
                        <ModelloForm
                            disableCondition={this.state.selMarca == null || this.state.anno == "" || this.state.modelli?.length == 0}
                            onChange={this.handleModelloChange}
                            modello={this.state.selModello != null ? this.state.selModello : "null"}
                            modelliList={this.state.modelli}
                        />
                    }
                    {this.state.selModello &&
                        <VersioneForm
                            disableCondition={this.state.selModello === null || this.state.versioni === undefined || this.state.versioni?.length == 0}
                            onChange={this.handleVersioneChange}
                            versione={this.state.selVersione}
                            versioniList={this.state.versioni}
                        />

                    }

                    {user_has_group(this.props.user, "RichiesteAvanzate") && this.state.selVersione &&
                        <VatTypeBox onChange={this.handleVatTypeChange}></VatTypeBox>
                    }

                    {user_has_group(this.props.user, "RichiesteAvanzate") && this.state.vatType !== VatTypeN.NULL && <PhotosUploader setAllPhotosFilledCallback={(areAllPhotosFilled) => this.setAllPhotosFilled(areAllPhotosFilled)} setPhotosPayload={(images) => this.setPhotosPayload({ images })} />}

                    {user_has_group(this.props.user, "RichiesteAvanzate") && this.state.areAllPhotosFilled && <Notes handleNotesChange={(evt) => this.setNotes(evt.target.value)} />}
                    <FormSubmitWithReset
                        user={this.props.user}
                        disableCondition={disableEvaluation}
                        onClickSubmit={() => this.handleSubmit(false)}
                        onClickAdvancedSubmit={() => this.handleSubmit(true)}
                        onClickReset={this.handleReset}
                    />

                </Form >

                {this.state.evaluating &&
                    <OutputBox
                        json={this.state.evalResponse}
                        km={parseInt(this.state.km) || 0}
                        immatr={`${String(this.state.anno).padStart(4, '0')}-${String(this.state.mese).padStart(2, '0')}-01`}
                        codes={this.state.codes}
                        user={this.props.user}
                        photos={this.state.photosPayload}
                        notes={this.state.notes}
                        evaluationId={this.state.evalResponse && this.state.evalResponse.success && this.state.evalResponse.success.rebounce && this.state.evalResponse.success.rebounce.evaluation && this.state.evalResponse.success.rebounce.evaluation.id}
                    />
                }
            </>
        );
    }
}

export default MmvForm;