/**
 * Checks if user has the specified group
 * @param {*} user 
 * @param {*} group 
 * @returns 
 */
export function user_has_group(user, group) {
    try {
        return user.signInUserSession.accessToken.payload["cognito:groups"].includes(group)
    } catch (e) {
        return false;
    }
}