import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default class DateKmForm extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const yearChange = this.props.onYearChange;
        const monthChange = this.props.onMonthChange;
        const year = this.props.year;
        const month = this.props.month;
        const kmChange = this.props.onKmChange;
        const km = this.props.km;
        return (
            <Row className="mb-3">
                <Form.Group
                    controlId="annoImmatrId"
                    as={Col}
                    md="4"
                >
                    <Form.Label>Anno Immatr.</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Anno"
                        onChange={yearChange}
                        value={year ? year : ""}
                    />
                </Form.Group>

                <Form.Group
                    controlId="meseImmatrId"
                    as={Col}
                    md="4"
                >
                    <Form.Label>Mese Immatr.</Form.Label>
                    <Form.Select
                        onChange={monthChange}
                        value={month ? month : ""}
                    >
                        <option value="" disabled hidden={month}>Mese</option>
                        {[...Array(12).keys()].map((v) => <option key={v} value={v + 1}>{v + 1}</option>)}
                    </Form.Select>
                </Form.Group>

                <Form.Group
                    controlId="kmId"
                    as={Col}
                    md="4"
                >
                    <Form.Label as={Col}>Chilometri</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Chilometri"
                        onChange={kmChange}
                        value={km ? km : ""}
                    />
                </Form.Group>
            </Row>
        )
    }
}