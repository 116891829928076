/** Endpoint per l'interrogazione della targa */
export const PLATE_API_ENDPOINT = "https://dbugbxlfx4.execute-api.eu-central-1.amazonaws.com/dev/targa";

/** Indicates the plate length */
export const PLATE_FORMAT_LENGTH = 7;

/** Regex for plate validity check */
export const PLATE_VALIDITY_REGEX = /^[A-Za-z]{2}[0-9]{3}[A-Za-z]{2}$/;

/** Indicates the vin length */
export const VIN_FORMAT_LENGTH = 17;

/** Regex for VIN validation */
export const VIN_VALIDITY_REGEX = /^[A-Za-z0-9]{17}$/;