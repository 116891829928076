import { Button } from "react-bootstrap";
import { useState } from "react";
import { Col, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import { PLATE_API_ENDPOINT, PLATE_FORMAT_LENGTH, VIN_FORMAT_LENGTH, VIN_VALIDITY_REGEX } from "../config";
import Icon from "@mdi/react";
import { mdiAlert, mdiReplay } from "@mdi/js";
import { VehicleDescriptor, checkPlateValidity } from "./plate-form-v2";
import evaluateVehicle from "./components/evaluation.tsx";
import OutputBox from "./output-box.tsx";

function checkVinValidity({ vin }) {
    return vin && vin.length >= VIN_FORMAT_LENGTH ? VIN_VALIDITY_REGEX.test(vin) : true
}

function searchCode({ user, code, doNext }) {
    if (code.length === PLATE_FORMAT_LENGTH) {
        fetch(PLATE_API_ENDPOINT, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${user.getSignInUserSession().getIdToken().getJwtToken()}`
            },
            body: JSON.stringify({ targa: code })
        }).then(res => res.json())
            .then(json => {
                try {
                    doNext({ user, vin: json.success.vin })
                } catch {
                    alert("L'interrogazione per telaio non ha restituito una risposta valida. Ci scusiamo per l'inconveniente, stiamo procedendo per la segnalazione a Motornet.");
                }
            })
    } else {
        doNext({ user, vin: code })
    }
}

export default function VinInput({ user }) {
    const [vin, setVin] = useState(null);
    const [finalVin, setFinalVin] = useState(null);
    const [finalPlate, setFinalPlate] = useState(null);
    const [versions, setVersions] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [km, setKm] = useState('');
    const [json, setJson] = useState(null);
    const [evalResponse, setEvalResponse] = useState(null);
    const [evaluating, setEvaluating] = useState(false);

    function handleReset(evt) {
        setVin(null);
        setFinalVin(null);
        setFinalPlate(null);
        setVersions(null);
        setSelectedVersion(null);
        setKm('');
        setJson(null);
        setEvalResponse(null);
        setEvaluating(false);
        evt.preventDefault();
    }

    function handleVinChange(evt) {
        // We do not allow inserting a longer VIN
        if (evt.target.value.length > VIN_FORMAT_LENGTH) { return };
        setVin(evt.target.value.toUpperCase());
    }

    function handleKmChange(evt) {
        setKm(evt.target.value)
    }

    function handleVersionChange(evt) {
        setSelectedVersion(versions[evt.target.selectedIndex]);
    }

    function handleEvaluationSubmit(extended) {
        setEvaluating(true);
        setEvalResponse(false);
        evaluateVehicle(
            user.getSignInUserSession().getIdToken().getJwtToken(),
            selectedVersion && selectedVersion.codiceMotornetUnivoco,
            json && json.dataImmatricolazione,
            parseInt(km),
            finalPlate,
            extended,
            () => { },
            (json) => { setEvalResponse(json); setEvaluating(false) },
            finalVin && finalVin.length === VIN_FORMAT_LENGTH ? finalVin : null,
        );
    }

    function handleCodeSubmit() {
        // If we have a plate in input box, we search for vin before applying the search
        // for VIN
        setFinalVin(null);
        setFinalPlate(null);
        setVersions(null);
        setSelectedVersion(null);
        setKm('');
        setJson(null);
        setEvalResponse(null);
        setEvaluating(false);
        if (vin.length === PLATE_FORMAT_LENGTH) {
            setFinalPlate(vin);
        }
        searchCode({
            user, code: vin, doNext: ({ user, vin }) => {
                setFinalVin(vin);
                fetch(PLATE_API_ENDPOINT, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${user.getSignInUserSession().getIdToken().getJwtToken()}`
                    },
                    body: JSON.stringify({ vin })
                }).then(res => res.json())
                    .then(json => {
                        try {
                            if (json.success.versioni.length === 0) {
                                alert("Motornet non ha restituito allestimenti associati al telaio, non è possibile procedere con l'identificazione tramite telaio.")
                                return;
                            }
                            setVersions(json.success.versioni);
                            setSelectedVersion(json.success.versioni[0]);
                            setJson(json.success);
                        } catch { }
                    })
                    .catch(() => alert("Impossibile ottenere una risposta da Motornet in questo momento. Ci scusiamo per il disagio, procederemo con la segnalazione al più presto."))
            }
        })
    }

    const invalidCode = vin && vin.length === PLATE_FORMAT_LENGTH ? !checkPlateValidity({ plate: vin }) : !checkVinValidity({ vin });

    return <Form id="vinForm">
        <Row className="mb-3">
            <Form.Group as={Col}>
                <div className="text-center">
                    <p><Icon path={mdiAlert} size={.8} /> <strong className="warning">ATTENZIONE:</strong> L'utilizzo di questo strumento prevede dei costi.</p>
                    <p>Nel campo sottostante può essere inserito il numero di <b>telaio</b> o la <b>targa</b>, la quale verrà utilizzata per recuperare il telaio.</p>
                    <p>L'identificazione della vettura avverrà tramite numero di telaio, con i servizi forniti da Motornet, secondo le loro limitazioni.</p>
                </div>
                <InputGroup>
                    <FloatingLabel label="Telaio o Targa">
                        <Form.Control
                            type="text"
                            value={vin || ''}
                            isInvalid={invalidCode}
                            aria-label="Campo di input per l'inserimento del telaio"
                            onChange={handleVinChange}
                            title="Telaio della vettura"
                        />
                    </FloatingLabel>
                    <Button
                        disabled={invalidCode || !vin || (vin && (vin.length !== VIN_FORMAT_LENGTH && vin.length !== PLATE_FORMAT_LENGTH))}
                        onClick={handleCodeSubmit}
                    >
                        Interroga Telaio
                    </Button>
                </InputGroup>
            </Form.Group>
        </Row>

        {versions && versions.length > 1 && <Row className="mb-3">
            <Col className="text-center">
                <p><Icon path={mdiAlert} size={.8} /> <strong className="warning">ATTENZIONE:</strong> Motornet ha restituito più di un allestimento associato con il telaio. Si è pregati di selezionare l'allestimento corretto.</p>
            </Col>
        </Row>}

        {versions && <Row className="mb-3">
            <Form.Group as={Col} className="col-12 col-md-8 mb-3 mb-md-0">
                <FloatingLabel label="Allestimento Rilevato">
                    <Form.Select
                        disabled={versions && versions.length === 1}
                        value={selectedVersion ? selectedVersion.codiceMotornetUnivoco : ''}
                        onChange={handleVersionChange}
                    >
                        {versions &&
                            versions.map((version, i) => {
                                return <option key={i} value={version.codiceMotornetUnivoco}>{version.versione}</option>
                            })
                        }
                    </Form.Select>
                </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} className="col-12 col-md-4">
                <FloatingLabel label="Chilometri">
                    <Form.Control type="number" step={1000} min={0} value={km} onChange={handleKmChange} />
                </FloatingLabel>
            </Form.Group>
        </Row>}

        {json && json.vehicleDesc && <Row className="mb-3">
            <VehicleDescriptor
                brand={json && json.vehicleDesc && json.vehicleDesc.brand}
                model={json && json.vehicleDesc && json.vehicleDesc.model}
                version={selectedVersion && selectedVersion.versione}
                eurotaxCode={selectedVersion && selectedVersion.codiceEurotax}
                motornetCode={selectedVersion && selectedVersion.codiceMotornetUnivoco}
                registrationDate={json && json.dataImmatricolazione}
                vin={finalVin}
            />
        </Row>}

        <Row className="mb-3">
            <InputGroup className="input-group-lg">
                <Button className="flex-grow-1" disabled={!json || !json.dataImmatricolazione || !km || !selectedVersion || !selectedVersion.codiceMotornetUnivoco} title="Ottieni Valutazione" onClick={() => handleEvaluationSubmit(false)}>Invia</Button>
                <Button variant="danger"><Icon path={mdiReplay} size={1} title="Reset" onClick={handleReset} /></Button>
            </InputGroup>
        </Row>

        {(evalResponse || evaluating) && <OutputBox
            immatr={json && json.dataImmatricolazione}
            km={km}
            json={evalResponse}
            user={user}
            modelInfo={!finalPlate && { brand: json && json.vehicleDesc && json.vehicleDesc.brand, model: json && json.vehicleDesc && json.vehicleDesc.model }}
        />}
    </Form>
}